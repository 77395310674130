/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

const Input = ({
	min,
	max,
	value,
	label,
	placeholder,
	onChange,
	validation,
	prefix,
}) => {

	const [valueTemp, setValueTemp] = useState(value);

	const currency = valueTemp !== undefined && valueTemp !== ""
		? prefix + Number(valueTemp).toLocaleString("id")
		: "";

	const minInt = min ? Number(min) : null;
	const maxInt = max ? Number(max) : null;

	let customError = "";

	if (maxInt > 0 && valueTemp && Number(valueTemp) > maxInt) {

		customError = "The value must be less than the maximum value";

	}

	if (minInt >= 0 && valueTemp && Number(valueTemp) < minInt) {

		customError = "The value must be greater than the minimum value";

	}

	const debounced = useDebouncedCallback((val) => {

		onChange(val);

	}, 1000);

	const handleChange = (val) => {

		let valFormat = "";

		if (val !== prefix) {

			valFormat = Number(val.replace(/\D+/g, ""));

		}

		setValueTemp(valFormat);
		debounced(valFormat);

	};

	useEffect(() => {

		setValueTemp(value);

	}, [value]);

	return (
		<div className="form-input-bs">
			{
				label && (
					<p className="label">{label}</p>
				)
			}
			<input
				value={currency}
				onChange={
					(e) => handleChange(e.target.value)
				}
				placeholder={placeholder}
				required
			/>
			{validation && <p className="error">{`${label} must be filled`}</p>}
			{!validation && customError.length > 0 && <p className="error">{customError}</p>}
			<style jsx>
				{`
				.form-input-bs {
					padding: 0 0 10px 0;
					
				}
				.form-input-bs p.label {
					margin: 0 0 5px 0;
					font-weight: 700;
					font-style: normal;
					font-size: 12px;
					line-height: 16px;
				}
				.form-input-bs p.error {
					margin: 4px 0;
					font-style: normal;
					font-size: 12px;
					line-height: 16px;
					color: #FF4D4F;
				}
				.form-input-bs input {
					width: 100%;
					box-sizing: border-box;
					height: 34px;
					border: 1px solid ${validation ? "red" : "#DFE3E8"};
					border-radius: 2px;
					outline: none;
					padding: 0 10px;
					font-weight: 400;
					font-style: normal;
					font-size: 14px;
					line-height: 22px;
					transition: all .3s ease;
				} 
				.form-input-bs input::placeholder {
					color: #919EAB;
				} 
				.form-input-bs input:focus,
				.form-input-bs input:hover {
					border: 1px solid #1B8884;
				} 
			`}
			</style>
		</div>
	);

};

Input.propTypes = {
	min: PropTypes.string,
	max: PropTypes.string,
	value: PropTypes.any,
	label: PropTypes.any,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	validation: PropTypes.bool,
	prefix: PropTypes.string,
};

Input.defaultProps = {
	placeholder: "please type ...",
	prefix: "Rp.",
};

export default Input;
