/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";

const Input = ({
 value, label, placeholder, onChange, validation,
 customErrorWording = false,
}) => {

	const phone = value.replace(/[^0-9]/g, "");
	let customError = "";
	if (phone.length > 0 && phone.length < 8) {

		customError = `${label} minimum 8 karakter`;

}
	if (phone.length > 0 && !/^0[0-9].*$/.test(phone)) {

		customError = `${label} harus diawali dengan 0`;

}
	if (phone.length === 1 && phone === "0") {

		customError = "";

}
	return (
		<div className="form-input-bs">
			{label && <p className="label">{label}</p>}
			<input
				value={phone}
				onChange={(e) => onChange(
						e.target.value.replace(/[^0-9]/g, "").length <= 13
							? e.target.value.replace(/[^0-9]/g, "")
							: phone,
					)}
				placeholder={placeholder}
				required
			/>
			{validation && (customErrorWording
				? <p className="error">{customErrorWording}</p>
				: <p className="error">{`${label} harus diisi`}</p>)}
			{!validation && customError.length > 0 && (
				<p className="error">{customError}</p>
			)}
			<style jsx>
				{`
					.form-input-bs {
						padding: 0 0 10px 0;
						
					}
					.form-input-bs p.label {
						margin: 0 0 5px 0;
						font-weight: 700;
						font-style: normal;
						font-size: 12px;
						line-height: 16px;
					}
					.form-input-bs p.error {
						margin: 4px 0;
						font-style: normal;
						font-size: 12px;
						line-height: 16px;
						color: #ff4d4f;
					}
					.form-input-bs input {
						width: 100%;
						box-sizing: border-box;
						height: 34px;
						border: 1px solid
							${customError.length > 0 || validation
								? "red"
								: "#DFE3E8"};
						border-radius: 2px;
						outline: none;
						padding: 0 10px;
						font-weight: 400;
						font-style: normal;
						font-size: 14px;
						line-height: 22px;
						transition: all 0.3s ease;
					}
					.form-input-bs input::placeholder {
						color: #919eab;
					}
					.form-input-bs input:focus,
					.form-input-bs input:hover {
						border: 1px solid #1b8884;
					}
				`}
			</style>
		</div>
	);

};

Input.propTypes = {
	value: PropTypes.any,
	label: PropTypes.any,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	validation: PropTypes.bool,
	customErrorWording: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
	]),
};

Input.defaultProps = {
	placeholder: "please type ...",
};

export default Input;
