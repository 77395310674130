/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import SelectForm from "react-select";

const stateColor = (state) => {

	if (state.isFocused) return "#1B8884";
	if (state.isSelected) return "#1B8884";
	return "#fff";

};

const customStyles = (validation, isOptionsShown = true) => ({
	option: (provided, state) => ({
		...provided,
		textTransform: "capitalize",
		maxHeight: "32px",
		fontSize: "14px",
		lineHeight: "22px",
		color: "#000",
		backgroundColor: stateColor(state),
	}),
	menuList: (provided) => ({
		...provided,
		maxHeight: "250px",
	}),
	menu: () => ({
		...(isOptionsShown ? {} : { display: "none", border: "0px" }),
	}),
	multiValue: (provided) => ({
		...provided,
		fontSize: "14px",
		lineHeight: "22px",
		color: "#000",
		borderRadius: "2px",
		padding: "1px",
	}),
	singleValue: (provided) => ({
		...provided,
		fontSize: "14px",
		lineHeight: "22px",
		color: "#000",
	}),
	NoOptionsMessage: (provided) => ({
		...provided,
		fontSize: "16px",
		color: "red",
	}),
	placeholder: (provided) => ({
		...provided,
		fontWeight: 400,
		fontStyle: "normal",
		letterSpacing: "normal",
		fontSize: "14px",
		lineHeight: "22px",
		color: "#919EAB",
	}),
	container: (provided) => ({
		...provided,
		// width: '458px',
	}),
	control: (provided) => ({
		...provided,
		textTransform: "normal",
		letterSpacing: "normal",
		height: "34px",
		borderRadius: "2px",
		borderColor: validation ? "red" : "#DFE3E8",
		boxShadow: "none",
		"&:hover": {
			borderColor: "#1B8884",
		},
	}),
	indicatorSeparator: () => ({ display: "none" }),
	dropdownIndicator: (base, state) => ({
		...base,
		transition: "all .2s ease",
		transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
	}),
});

const Select = ({
	value,
	label,
	placeholder,
	onChange,
	onClick,
	isLoading,
	isDisabled,
	options,
	validation,
	isSearchable,
	isOptionsShown,
}) => (
	<div className="form-select-bs">
		{label && <p className="label">{label}</p>}
		<SelectForm
			styles={customStyles(validation, isOptionsShown)}
			options={options}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			onMenuOpen={onClick}
			isLoading={isLoading}
			isDisabled={isDisabled}
			isSearchable={isSearchable}
		/>
		{validation && <p className="error">{`${label} harus diisi`}</p>}
		<style jsx>
			{`
				.form-select-bs {
					padding: 0 0 10px 0;
					
				}
				.form-select-bs p.label {
					margin: 0 0 5px 0;
					font-weight: 700;
					font-style: normal;
					font-size: 12px;
					line-height: 16px;
				}
				p.error {
					margin: 4px 0;
					font-style: normal;
					font-size: 12px;
					line-height: 16px;
					color: #ff4d4f;
				}
			`}
		</style>
	</div>
);

Select.propTypes = {
	value: PropTypes.any,
	label: PropTypes.any,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	options: PropTypes.any,
	validation: PropTypes.bool,
	isSearchable: PropTypes.bool,
	isOptionsShown: PropTypes.bool,
};

Select.defaultProps = {
	placeholder: "please select ...",
	isLoading: false,
	isDisabled: false,
	isSearchable: true,
	isOptionsShown: true,
};

export default Select;
