/* eslint-disable react/no-children-prop */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types";
import React from "react";
import Default from "./default";
import Phone from "./phone";
import PostalCode from "./postalcode";
import Currency from "./currency";
import Search from "./search";

const Template = [
	{
		id: "default",
		component: Default,
	},
	{
		id: "phone",
		component: Phone,
	},
	{
		id: "postalcode",
		component: PostalCode,
	},
	{
		id: "search",
		component: Search,
	},
	{
		id: "currency",
		component: Currency,
	},
];

const ChildDetailPage = ({
	type,
	...otherProps
}) => (
	Template.filter((item) => item.id === type).map((style, idx) => (
		<style.component
			key={`input-${type}-${String(idx)}`}
			{...otherProps}
		/>
	))
);

ChildDetailPage.propTypes = {
	type: PropTypes.string,
};

ChildDetailPage.defaultProps = {
	type: "default",
};

export default ChildDetailPage;
